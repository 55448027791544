angular.module('LeasePilot').service('ApiService', [
  '$http',
  function($http) {
    function convert(file, path) {
      var type =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      var formData = new FormData();
      formData.append('file', file);
      return $http.post('/api/services/' + path, formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, Accept: type },
        responseType: 'arraybuffer',
      });
    }

    function getJson(file, path) {
      var formData = new FormData();
      formData.append('file', file);
      return $http.post('/api/services/' + path, formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, Accept: 'html/text' },
      });
    }

    function downloadFile(filename, type, data) {
      var a = document.createElement('a');
      var file = new Blob([data], {
        type: type,
      });
      var url = URL.createObjectURL(file);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    function zipFiles(files, callback) {
      var zip = new JSZip();

      files.forEach(file => {
        zip.file(file.name, file.file);
      });

      return zip.generateAsync({ type: "blob" });
    }

    var Base64 = {
      // private property
      _keyStr:
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=',

      // public method for encoding
      encode: function(input) {
        var output = '';
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        input = Base64._utf8_encode(input);

        while (i < input.length) {
          chr1 = input.charCodeAt(i++);
          chr2 = input.charCodeAt(i++);
          chr3 = input.charCodeAt(i++);

          enc1 = chr1 >> 2;
          enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
          enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
          enc4 = chr3 & 63;

          if (isNaN(chr2)) {
            enc3 = enc4 = 64;
          } else if (isNaN(chr3)) {
            enc4 = 64;
          }

          output =
            output +
            this._keyStr.charAt(enc1) +
            this._keyStr.charAt(enc2) +
            this._keyStr.charAt(enc3) +
            this._keyStr.charAt(enc4);
        } // Whend

        return output;
      }, // End Function encode

      // private method for UTF-8 encoding
      _utf8_encode: function(string) {
        var utftext = '';
        string = string.replace(/\r\n/g, '\n');

        for (var n = 0; n < string.length; n++) {
          var c = string.charCodeAt(n);

          if (c < 128) {
            utftext += String.fromCharCode(c);
          } else if (c > 127 && c < 2048) {
            utftext += String.fromCharCode((c >> 6) | 192);
            utftext += String.fromCharCode((c & 63) | 128);
          } else {
            utftext += String.fromCharCode((c >> 12) | 224);
            utftext += String.fromCharCode(((c >> 6) & 63) | 128);
            utftext += String.fromCharCode((c & 63) | 128);
          }
        } // Next n

        return utftext;
      }, // End Function _utf8_encode
    };

    function import_(file, path, params) {
      var protocol = location.protocol === 'https:' ? 'https' : 'http';
      var type =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      var formData = new FormData();
      formData.append('file', file);
      for (var key in params) {
        formData.append(key, params[key]);
      }
      var url = window._importer_service_url;
      return $http.post(protocol + '://' + url + path, formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, Accept: type },
        responseType: 'arraybuffer',
      });
    }

    function zipToRedline(file) {
      return convert(file, 'zip_to_redline');
    }

    async function htmlToWord(file, desiredStylesUrl) {
      let request = new XMLHttpRequest();

      return new Promise(function (resolve, reject) {
        request.onload = function () {
          const filesToZip = [
            { name: 'document.html', file },
            { name: 'style.docx', file: request.response },
          ];

          zipFiles(filesToZip).then(function (zipFile) {
            if (window.isDebug) {
              downloadFile("download_document.zip", "application/zip", zipFile);
            }
            resolve(convert(zipFile, 'html_to_word'));
          });
        };

        request.open('GET', desiredStylesUrl, true);
        request.responseType = "blob";
        request.send();
      });
    }


    async function htmlToClausebook(file, desiredStylesUrl) {
      
      let request = new XMLHttpRequest();

      return new Promise(function(resolve, reject) {
        request.onload = function() {
          const filesToZip = [
            { name: "document.html", file },
            { name: "style.docx", file: request.response }
          ];

          zipFiles(filesToZip).then(function(zipFile) {
            if (window.isDebug) {
              downloadFile("download_document.zip", "application/zip", zipFile);
            }
            resolve(getJson(zipFile, "html_to_clausebook"));
          });
        };

        request.open("GET", desiredStylesUrl, true);
        request.responseType = "blob";
        request.send();
      });
    }

    function htmlToPdf(file, desiredStylesUrl) {
      let request = new XMLHttpRequest();

      return new Promise(function (resolve, reject) {
        request.onload = function () {
          const filesToZip = [{
              name: 'document.html',
              file
            },
            {
              name: 'style.docx',
              file: request.response
            },
          ];

          zipFiles(filesToZip).then(function (zipFile) {
            resolve(convert(zipFile, 'html_to_pdf'));
          });
        };

        request.open('GET', desiredStylesUrl, true);
        request.responseType = "blob";
        request.send();
      });
    }

    function documentToPdf(file) {
      return convert(file, 'document_to_pdf');
    }

    function documentToHTML(file) {
      const path = "document_to_html";
      const formData = new FormData();
      formData.append('file', file);
      return $http.post('/api/services/' + path, formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, Accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
      });
    }

    function documentToText(file) {
      const path = "document_to_text";
      const formData = new FormData();
      formData.append('file', file);
      return $http.post('/api/services/' + path, formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, Accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" },
      });
    }

    function SMLToMSWord(file, formName) {
      return import_(file, '', {
        'add-free-texts': 'on',
        'download-word': 'on',
        'form-name': formName,
        form: 'sml',
      });
    }

    function SMLToMSWordWithChanges(file, changes) {
      console.log(file, {
        form: 'changes',
        changes: Base64.encode(JSON.stringify(changes)),
      });
      return import_(file, '', {
        form: 'changes',
        changes: Base64.encode(JSON.stringify(changes)),
      });
    }

    function diffgram(original, modified, desiredStylesUrl) {      
      let request = new XMLHttpRequest();

      return new Promise(function (resolve, reject) {
        request.onload = function () {
          const filesToZip = [{
              name: 'original.html',
              file: original,
            },
            {
              name: 'modified.docx',
              file: modified,
            },
            {
              name: 'style.docx',
              file: request.response,
            },
          ];

          zipFiles(filesToZip).then(function (zipFile) {
            // remove comment to download zip file
            if (window.isDebug) {
              downloadFile("diff.zip", "application/zip", zipFile);
            }
            resolve(getJson(zipFile, "diffgram"));
          });
        };

        request.open('GET', desiredStylesUrl, true);
        request.responseType = "blob";
        request.send();
      });
    }


    function redlineToHTML(file) {
      return convert(file, 'redline_to_html');
    }

    function rtfToHtml(data) {
      const type = "application/text";
      const formData = new FormData();

      formData.append("rtf", data);

      return $http.post('/api/services/rtf_to_html', formData, {
        transformRequest: angular.identity,
        headers: { "Content-Type": undefined, Accept: type },
        responseType: "arraybuffer"
      });
    }

    function snippet(data){

      var protocol = location.protocol === 'https:' ? 'https' : 'http';
      var type =
        'application/text';
      var formData = new FormData();
      formData.append('form', 'snippet');
      formData.append('snippet', data);
      var url = window._importer_service_url;
      return $http.post(protocol + '://' + url , formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, Accept: type },
        responseType: 'arraybuffer',
      });

    }

    function abstract({ terms, doc, instructions = "", format = "", documentType = "", documentFlavor = ""}) {     
      if (!doc) {
        throw new Error('Document is required');
      }
      
      const type = 'application/text';
      const formData = new FormData();

      formData.append('terms', JSON.stringify(terms));
      formData.append('document', doc);
      formData.append('instructions', instructions);
      formData.append('documentFormat', format);
      formData.append('documentType', documentType);
      formData.append('documentFlavor', documentFlavor);

      return $http.post('/api/services/abstract', formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, Accept: type },
        responseType: 'plain/text',
        timeout: 300 * 1000,
      });
    }

    function saveAbstract({ documentId, json, docx }) {     
      const type = 'application/text';
      const formData = new FormData();

      formData.append('documentId', documentId);

      if (json) {
        formData.append('json', json);
      }
      
      if (docx) {
        formData.append('docx', docx);
      }

      return $http.post('/api/services/save_abstract', formData, {
        transformRequest: angular.identity,
        headers: { 'Content-Type': undefined, Accept: type },
        responseType: 'plain/text',
      });
    }

    return {
      convert: convert,
      htmlToWord: htmlToWord,
      zipToRedline: zipToRedline,
      htmlToPdf: htmlToPdf,
      SMLToMSWord: SMLToMSWord,
      SMLToMSWordWithChanges: SMLToMSWordWithChanges,
      documentToPdf: documentToPdf,
      documentToHTML: documentToHTML,
      documentToText: documentToText,
      diffgram: diffgram,
      redlineToHTML: redlineToHTML,
      snippet:snippet,
      rtfToHtml: rtfToHtml,
      htmlToClausebook: htmlToClausebook,
      abstract: abstract,
      saveAbstract: saveAbstract,
    };
  },
]);
